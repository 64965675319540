import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const CountVen = () => {
  const data = useStaticQuery(graphql`
    {
      allGoogleSpreadsheetDiccionarioIlogicoRespuestasDeFormulario1(
        filter: {pa_s: {in: "Venezuela"}}
      ) {
        totalCount
      }
    }
  `)
  return <>{data.allGoogleSpreadsheetDiccionarioIlogicoRespuestasDeFormulario1.totalCount}</>
}

export default CountVen

