import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const CountCol = () => {
  const data = useStaticQuery(graphql`
    {
      allGoogleSpreadsheetDiccionarioIlogicoRespuestasDeFormulario1(
        filter: {pa_s: {in: "Colombia"}}
      ) {
        totalCount
      }
    }
  `)
  return <>{data.allGoogleSpreadsheetDiccionarioIlogicoRespuestasDeFormulario1.totalCount}</>
}

export default CountCol

