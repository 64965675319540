import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const BuildTime = () => {
  const data = useStaticQuery(graphql`
    {
      siteBuildMetadata {
        buildTime(formatString: "DD-MM-YYYY")
      }
    }
  `)
  return(
  <>
    {data.siteBuildMetadata.buildTime}
  </>
  )
}

export default BuildTime
