import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const WordCount = () => {
  const data = useStaticQuery(graphql`
    {
      allGoogleSpreadsheetDiccionarioIlogicoRespuestasDeFormulario1 {
        totalCount
      }
    }
  `)
  return <>{data.allGoogleSpreadsheetDiccionarioIlogicoRespuestasDeFormulario1.totalCount}</>
}

export default WordCount

