import React, { PureComponent } from 'react'
import { Link } from 'gatsby'
import CountCL from './counters/CountCL'
import CountCol from './counters/CountCol';
import CountVen from './counters/CountVen';
import WordCount from './WordCount';

export default class CountryList extends PureComponent {
  render() {
    return (
      <>
        <div className="block">
          <h3>Filtrar:</h3>
          <Link to="/chile">🇨🇱 Chile <span>(<CountCL />)</span></Link>
          <Link to="/colombia">🇨🇴 Colombia <span>(<CountCol />)</span></Link>
          <Link to="/venezuela">🇻🇪 Venezuela <span>(<CountVen />)</span></Link>
          <Link to="/">🌎 Ver todos <span>(<WordCount />)</span></Link>
        </div>
      </>
    )
  }
}
